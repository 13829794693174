<template>
    <div :class="[isMobile || this.view === 'compact' ? 'mobile-content' : 'content']">
        <div class="box-title">
            <h2 class="content__title ultra-blue">
                Расположение на карте
            </h2>
        </div>

        <div class="map__container" v-if="pageReady">
            <yandex-map
                    v-if="pageReady"
                    v-bind:class="{loading: loading===true}"
                    class="map"
                    :coords="[home_coordinates.lat, home_coordinates.lng]"
                    zoom="14"
                    :behaviors="['drag', 'dblClickZoom']"
                    :cluster-options="{1: {clusterDisableClickZoom: false}}"
                    :controls="['zoomControl']"
                    map-type="map"
            >
                <ymap-marker
                        :markerId=1
                        :coords="[home_coordinates.lat, home_coordinates.lng]"
                        marker-type="placemark"
                        :icon="markerIcon"
                ></ymap-marker>
            </yandex-map>
        </div>

    </div>
</template>


<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
    components: {
        yandexMap, ymapMarker
    },
    props: ['home_coordinates', 'view'],
    data() {
        return {
            pageReady: false,
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: '',
                imageSize: [24, 24],
                imageOffset: [0, 0],
                contentOffset: [0, 0],
                contentLayout: '<div style="background: rgba(63, 112, 155); border: 2px solid white; width: 20px; height: 20px; border-radius: 20px"></div>'
            },
        }
    },
    mounted() {
        this.pageReady = true;
    }
}
</script>

<style lang="scss" scoped>
.map {
    width: 100%;
    height: 100%;
    &__container {
        flex: 1 1 auto;
        max-width: 100%;
        height: 480px;
        border: 2px solid rgb(240, 240, 240);
        border-radius: 4px;
        overflow: hidden;
    }
}
</style>