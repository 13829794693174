<template>
    <div :class="[isMobile || this.view === 'compact' ? 'mobile-content' : 'content']">

        <div class="box-title">
            <h2 class="content__title ultra-blue">
                Описание
            </h2>
        </div>

        <div :class="[isMobile || this.view === 'compact' ? 'mobile-content' : 'content']">
            <div
                    ref="desc"
                    v-html="description"
                    class="ultra-blue"
                    :class="[isMobile || this.view === 'compact' ? 'mobile-desc ' : 'desc', height > 450 && opened === false ? 'desc__closed' : '']">
            ></div>

            <div class="gradient" v-if="height > 450 && opened === false"></div>

            <div class="desc__footer" v-if="height > 450">
                <div class="desc__button" v-on:click="open" v-if="opened === false">
                    Показать весь текст
                </div>
                <div class="desc__button" v-on:click="close" v-if="opened === true">
                    Скрыть текст
                </div>
            </div>
        </div>

    </div>
</template>


<script>
export default {
    props: ['description', 'view'],
    data() {
        return {
            height: 0,
            opened: false,
        }
    },
    mounted() {
        this.get_description_height();
    },
    methods: {
        get_description_height() {
            this.height = this.$refs.desc.clientHeight;
        },
        open() {
            this.opened = true;
        },
        close() {
            this.opened = false;
        },
    },

}
</script>

<style lang="scss" scoped>
.gradient {
    position: relative;
    z-index: 50;
    margin-top: -50px;
    max-width: 100%;
    height: 50px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.desc {
    font: 14px proxima-regular;
    letter-spacing: 0.01em;
    line-height: 1.5em;
    padding-right: 20px;
    margin-left: -20px;
    &__closed {
        max-height: 450px;
        overflow: hidden;
    }
    &__footer {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    &__button {
        position: relative;
        margin: 0 auto;
        padding: 0 25px;
        height: 38px;
        background: #ffe062;
        cursor: pointer;
        font: 14px proxima-regular;
        line-height: 38px;
        text-align: center;
        border-radius: 38px;
        &:hover {
            background: rgb(255, 234, 147);
        }
    }
}
.mobile-desc {
    font: 14px proxima-regular;
    letter-spacing: 0.01em;
    line-height: 1.5em;
    margin-bottom: 50px;
}
</style>