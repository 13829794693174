<template>
    <div :class="[isCompact ? 'mobile-content' : 'content__570']">

        <div :class="[isCompact ? 'mobile-gallery' : 'gallery']" v-if="gallery_count > 0">
            <img :class="[isCompact ? 'mobile-gallery__img' : 'gallery__img']" :src="gallery[number-1].imgurl"/>
            <div :class="[isCompact ? 'mobile-gallery__box' : 'gallery__box']" >

                <div :class="[isCompact ? 'mobile-gallery__switch' : 'gallery__switch']">
                    <div class="gallery__button rotate" @click="prev">
                        <div class="line_right_top"></div>
                        <div class="line_right_bot"></div>
                    </div>
                </div>

                <div :class="[isCompact ? 'mobile-gallery__area' : 'gallery__area']" @click="next"></div>

                <div :class="[isCompact ? 'mobile-gallery__switch' : 'gallery__switch']">
                    <div class="gallery__mini">
                        <div class="gallery__increase" @click="open_full">+</div>
                    </div>
                    <div class="gallery__large">
                        <div class="gallery__button" @click="next">
                            <div class="line_right_top"></div>
                            <div class="line_right_bot"></div>
                        </div>
                    </div>
                    <div class="gallery__mini">
                        <div class="gallery__count">
                            {{ number }} / {{gallery_count}}
                        </div>
                    </div>


                </div>

            </div>
        </div>

        <div class="gallery__plug" v-else>НЕТ ФОТО</div>

        <div class="fullscreen" v-if="is_open_full && gallery_count > 0">
            <img class="img__full" :src="gallery[number-1].imgurl" v-on:click="next"/>

            <div class="full__left">
                <div class="full__button rotate" @click="prev">
                    <div class="full__line_top"></div>
                    <div class="full__line_bot"></div>
                </div>
            </div>

            <div class="full__right">
                <div class="close" @click="close_full">
                    <div class="close__line_top"></div>
                    <div class="close__line_bot"></div>
                </div>

                <div class="full__right__middle">
                    <div class="full__button full__button_right" @click="next">
                        <div class="full__line_top"></div>
                        <div class="full__line_bot"></div>
                    </div>
                </div>

                <div class="full__right__bottom">
                    <div class="full__count">{{number}} / {{gallery_count}}</div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    props: ['gallery_count', 'gallery', 'view'],
    data() {
        return {
            number: 1,
            is_open_full: false,
        }
    },
    computed: {
        isCompact() {
            return this.isMobile || this.view === 'compact'
        }
    },
    methods: {
        next() {
            if (this.number < this.gallery_count) {
                this.number++;
            } else {
                this.number = 1;
            }

        },
        prev() {
            if (this.number > 1) {
                this.number--;
            } else {
                this.number = this.gallery_count;
            }

        },
        open_full() {
            this.is_open_full = true;
        },
        close_full() {
            this.is_open_full = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.gallery__plug {
    width: 567px;
    height: 420px;
    background: rgb(245, 245, 245);
    border-radius: 4px;
    overflow: hidden;
    font: 30px proxima-bold;
    color: rgb(220, 220, 220);
    line-height: 420px;
    text-align: center;
    letter-spacing: 0.1em;
}
.fullscreen {
    display: flex;
    position: fixed;
    padding: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    overflow: auto;
    z-index: 50;
}
.img__full {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    cursor: pointer;
}
.full {
    &__left {
        display: flex;
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: 20px;
        right: 0;
        width: 40px;
        cursor: pointer;
    }
    &__right {
        flex-direction: column;
        display: flex;
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: auto;
        right: 20px;
        width: 60px;
        cursor: pointer;
        &__middle {
            display: flex;
            flex: 1 1 0;
        }
        &__bottom {
            display: flex;
            flex: 1 1 1;
            max-width: 60px;
            min-width: 60px;
            height: 40px;
        }
    }
    &__count {
        flex: 1 1 0;
        max-width: 100%;
        height: 23px;
        margin: auto 0 0 0;
        background: white;
        border-radius: 3px;
        font: 14px proxima-bold;
        text-align: center;
        line-height: 24px;
    }
    &__button {
        margin: auto;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: white;
        box-shadow: 0 8px 24px 0 rgba(0,0,0,.16);
        cursor: pointer;
        &_right {
            margin: auto 0 auto 20px;
        }
        &:hover .full__line_top {
            background: rgb(200, 200, 200);
        } 
        &:hover .full__line_bot {
            background: rgb(200, 200, 200);
        } 
    }
    &__line {
        &_top {
            width: 16px;
            height: 4px;
            margin: 14px 0 0 14px;
            background: rgb(220, 220, 220);
            transform: rotate(45deg);
        }
        &_bot {
            width: 16px;
            height: 4px;
            margin: 5px 0 0 14px;
            background: rgb(220, 220, 220);
            transform: rotate(-45deg);
        }
    }
}
.close {
    display: flex;
    margin-left: 20px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    cursor: pointer;
    background: white;
    transform: rotate(45deg);
    &__line_top {
        width: 2px;
        height: 24px;
        border-radius: 3px;
        background: rgb(220, 220, 220);
        margin: 8px 0 0 19px;
    }
    &__line_bot {
        width: 24px;
        height: 2px;
        border-radius: 3px;
        background: rgb(220, 220, 220);
        margin: 19px 0 0 -13px;
    }
    &:hover .close__line_top {
        background: rgb(200, 200, 200);
    } 
    &:hover .close__line_bot {
        background: rgb(200, 200, 200);
    } 
}
.gallery {
    width: 567px;
    height: 420px;
    background: rgb(240, 240, 240);
    border-radius: 4px;
    overflow: hidden;
    &__img {
        object-fit: cover;
        position: absolute;
        border-radius: 4px;
        width: 567px;
        height: 420px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    &__box {
        opacity: 0;
        border-radius: 4px;
        overflow: hidden;
        position: absolute;
        display: flex;
        width: 567px;
        height: 420px;
        transition: .1s;
        &:hover {
            opacity: 1;
        }
    }
    &__switch {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        max-width: 60px;
        height: 420px;
    }
    &__area {
        flex: 1 1 0;
        max-width: 447px;
        height: 420px;
        cursor: pointer;
    }
    &__count {
        margin: auto 10px 10px auto;
        width: 60px;
        height: 21px;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 3px;
        font: 12px proxima-regular;
        color: white;
        line-height: 22px;
        text-align: center;
    }
    &__mini {
        display: flex;
        width: 60px;
        height: 60px;
    }
    &__large {
        display: flex;
        width: 60px;
        height: 300px;
    }
    &__increase {
        margin: auto;
        width: 31px;
        height: 31px;
        border-radius: 31px;
        background: rgba(0, 0, 0, 0.3);
        box-shadow: 0 8px 24px 0 rgba(0,0,0,.16);
        cursor: pointer;
        font: 22px proxima-bold;
        line-height: 33px;
        text-align: center;
        color: white;
        &:hover { 
            background: rgba(0, 0, 0, 0.7);
        }
    }
    &__button {
        margin: auto;
        width: 31px;
        height: 31px;
        border-radius: 30px;
        background: white;
        box-shadow: 0 8px 24px 0 rgba(0,0,0,.16);
        cursor: pointer;
        &:hover .line_right_top {
            background: rgb(200, 200, 200);
        }
        &:hover .line_right_bot {
            background: rgb(200, 200, 200);
        }
    }
}
.mobile-gallery {
    width: 100%;
    height: 30vh;
    background: rgb(240, 240, 240);
    border-radius: 4px;
    overflow: hidden;
    &__img {
        object-fit: cover;
        position: absolute;
        border-radius: 4px;
        width: 90%;
        height: 30vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    &__box {
        opacity: 0;
        border-radius: 4px;
        overflow: hidden;
        position: absolute;
        display: flex;
        width: 90%;
        height: 30vh;
        transition: .1s;
        opacity: 1;
    }
    &__switch {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        max-width: 60px;
        height: 30vh;
    }
    &__area {
        flex: 1 1 0;
        max-width: 447px;
        height: 30vh;
        cursor: pointer;
    }
}
.rotate {
    transform: rotate(180deg);
}
.line_right_top {
    width: 11px;
    height: 3px;
    margin: 11px 0 0 11px;
    background: rgb(220, 220, 220);
    border-radius: 10px;
    transform: rotate(45deg);
}
.line_right_bot {
    width: 11px;
    height: 3px;
    margin: 3px 0 0 11px;
    background: rgb(220, 220, 220);
    border-radius: 10px;
    transform: rotate(-45deg);
}
</style>