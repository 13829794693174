<template>
    <div v-if="!isMobile && view !== 'compact'" class="home_cont">
        <preloader class="home__preloader" v-if="loading" />
        <div v-else>

            <div class="content">
                <Gallery
                        v-if="home.images"
                        :gallery_count="home.images.length"
                        :gallery="home.images"
                />

                <HomeInfo v-if="home" :home="home"/>
            </div>

            <Description :description="home.description" />

            <Map
                    :home_coordinates="home.coords"
            />

        </div>
    </div>
    <div class="mobile-home_cont" v-else>
        <preloader class="mobile-home__preloader" v-if="loading" />
        <div v-else>

            <Gallery
                    v-if="home.images"
                    :gallery_count="home.images.length"
                    :gallery="home.images"
                    view="compact"
            />

            <HomeInfo v-if="home" :home="home" view="compact" />

            <Description :description="home.description" view="compact"/>

            <Map
                    :home_coordinates="home.coords"
                    view="compact"
            />

        </div>
    </div>

</template>

<script>
import Preloader from '@/components/Preloader'
import Gallery from '@/components/Object/Gallery'
import HomeInfo from '@/components/Object/HomeInfo'
import Description from '@/components/Object/Description'
import Map from '@/components/Object/Map'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Home',
    props: ['view', 'id'],
    components: {
        Preloader, Gallery, HomeInfo, Description, Map, 
    },
    computed: {
        ...mapGetters(['home'])
    },
    mounted() {
        window.scrollTo({ top: 0 });
        if (this.view !== 'compact') this.fetchHome(this.$route.params.id);
        this.fetchFavs();
    },
    methods: {
        ...mapActions(['fetchHome'])
    },
}
</script>

<style>
.home_cont {
    margin: 120px auto 0px auto;
    max-width: 1250px;
}
.mobile-home_cont {
    margin-top: 70px;
    padding: 20px;
}
.mobile-home__preloader {
    margin: calc(75vh / 2) auto;
}
.home__preloader {
    padding: 400px 70px;
}
.box-title {
    flex: 1 1 1;
    width: 100%;
    border-bottom: 1px solid rgb(240, 240, 240);
    margin-bottom: 20px;
}
.content__title {
    font-size: 28px;
    margin-bottom: 10px;
}
</style>