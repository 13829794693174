<template>
    <div class="info" :class="[isMobile  || this.view === 'compact' ? 'mobile-content mobile-info' : 'content__600']">

        <h1 class="ultra-blue" :class="[isMobile  || this.view === 'compact' ? 'mobile-info__name' : 'info__name']" v-if="home.title">
            {{title}}
        </h1>

        <div class="top-params__wrapper">
            <div class="top-params">
                <div class="text blue">{{type}}</div>
                <div class="text blue">{{category}}</div>
                <div class="text blue">{{home.sub_category}}</div>
                <div class="text blue">{{home.address}}</div>
                <div class="text blue">{{home.district}}</div>
            </div>
            <div class="fav__wrapper">
                <div class="fav" @click="!isFav ? addFav(home.id) : removeFav(home.id)">
                    <svg :class="{ fav__full: isFav }" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24"><path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/></svg>
                </div>
            </div>
        </div>

        <div :class="[isMobile || this.view === 'compact' ? 'mobile-params__cont' : 'params__cont']">
            <div class="params">
                <div class="params__title ultra-blue">Параметры дома</div>
                <div class="params__container">

                    <div class="params__row" v-if="home.floor">
                        <div class="params__half_40">
                            <div class="text">Этаж</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ home.floor.one ? home.floor.one : '?' }} {{ home.floor.full ? ` из ${home.floor.full}` : 'из ?' }}</div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.category !== 5 || home.category !== 6">
                        <div class="params__half_40">
                            <div class="text">{{`Комнат${home.category === 2 ? 'а' : ''} `}}</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ 
                                        home.full_rooms && home.category === 3
                                        ? `${home.rooms} из ${home.full_rooms}` 
                                        : home.rooms ? home.rooms === -1 ? 'Студия' : home.rooms : '?' 
                                    }}
                            </div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.sizes">
                        <div class="params__half_40">
                            <div class="text">Общая площадь</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ home.sizes.full ? `${home.sizes.full} ${ home.category === 5 ? 'сот.' : 'м²'}` : '?' }}</div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.sizes">
                        <div class="params__half_40">
                            <div class="text">Площадь кухни</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ home.sizes.kitchen ? `${home.sizes.kitchen} м²` : '?' }}</div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.sizes">
                        <div class="params__half_40">
                            <div class="text">Жилая площадь</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ home.sizes.living ? `${home.sizes.living} м²` : '?' }}</div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.material">
                        <div class="params__half_40">
                            <div class="text">Тип стен</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ home.material.length ? home.material : '?' }}</div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.type === 2 && home.comission">
                        <div class="params__half_40">
                            <div class="text">Комиссия</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ home.comission.length ? home.comission_price.length ? home.comission_price : home.comission : '?' }}</div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.sub_category === 'Новостройка' && home.ready_date">
                        <div class="params__half_40">
                            <div class="text">Срок сдачи</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ home.ready_date.length ? home.ready_date : '?' }}</div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="params">
                <div class="params__title ultra-blue">Параметры объявления</div>
                <div class="params__container">

                    <div class="params__row" v-if="home.contact">
                        <div class="params__half_40">
                            <div class="text">Имя</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ home.contact.name.length ? home.contact.name : '?'}}</div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.contact">
                        <div class="params__half_40">
                            <div class="text">Собственник</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">
                                {{ home.contact.person_type ? home.contact.person_type === 3 ? 'да' : 'нет' : '?'}}
                            </div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.contact">
                        <div class="params__half_40">
                            <div class="text">Телефон</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ home.contact.phone.length ? home.contact.phone : '?'}}</div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.created">
                        <div class="params__half_40">
                            <div class="text">Дата</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">{{ created }}</div>
                        </div>
                    </div>

                    <div class="params__row" v-if="home.url && home.source">
                        <div class="params__half_40">
                            <div class="text">Источник</div>
                            <div class="params__line"></div>
                        </div>
                        <div class="params__half_60">
                            <div class="text">
                                <a :href="home.url" class="url" target="_blank">{{ source }}</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="offers" v-if="home.price">
            <div class="offers__count ultra-blue">
                Цена: {{ home.price > 1000 ? home.price / 1000 + ' тыс.' : home.price }} руб. {{ home.type === 2 ? home.rent_type === 'Посуточно' ? '/ сут.' : '/ мес.' : ''}}
            </div>
        </div>

    </div>
</template>


<script>
export default {
    props: ['home', 'view'],
    computed: {
        isFav() {
            return this.favs.includes(this.home.id);
        },
        title() {
            return this.home.title.replace('Красноярский край, Красноярск', '');
        },
        type() {
            var result = '';

            switch (this.home.type) {
                case 1: {
                    result = 'Продам'
                    break;
                }
                case 2: {
                    result = 'Сдам'
                    break;
                }
                case 3: {
                    result = 'Куплю'
                    break;
                }
                case 4: {
                    result = 'Сниму'
                    break;
                }
            }
            return result;
        },
        category() {
            var result = '';

            switch (this.home.category) {
                case 2: {
                    result = 'Квартира'
                    break;
                }
                case 3: {
                    result = 'Комната'
                    break;
                }
                case 4: {
                    result = 'Дом, коттедж, дача'
                    break;
                }
                case 5: {
                    result = 'Земельный участок'
                    break;
                }
                case 6: {
                    result = 'Гараж'
                    break;
                }
                case 7: {
                    result = 'Коммерческое здание'
                    break;
                }
            }
            return result;
        },
        source() {
            var result = '';

            switch (this.home.source) {
                case '1': {
                    result = 'Авито'
                    break;
                }
                case '2': {
                    result = 'Из рук в руки'
                    break;
                }
                case '3': {
                    result = 'Яндекс.Недвижимость'
                    break;
                }
                case '4': {
                    result = 'Циан'
                    break;
                }
                case '6': {
                    result = 'Юла'
                    break;
                }
                case '7': {
                    result = 'N1'
                    break;
                }
            }
            return result;
        },
        created() {
            const date = this.home.created.substring(5,10);
            const time = this.home.created.substring(11,16);
            const month = date.substring(0,2)
            const day = date.substring(3,5)

            var result = '';

            switch (month) {
                case '01': 
                    result = 'янв'
                    break;

                case '02': 
                    result = 'фев'
                    break;
                
                case '03': 
                    result = 'мар'
                    break;
                
                case '04': 
                    result = 'апр'
                    break;
                
                case '05': 
                    result = 'мая'
                    break;
                
                case '06': 
                    result = 'июн'
                    break;
                
                case '07': 
                    result = 'июл'
                    break;

                case '08': 
                    result = 'авг'
                    break;
                
                case '09': 
                    result = 'сен'
                    break;
                
                case '10': 
                    result = 'окт'
                    break;
                
                case '11': 
                    result = 'ноя'
                    break;
                
                case '12': 
                    result = 'дек'
                    break;
                
            }
            return `${day} ${result} ${time}`;
        },
    },
}
</script>

<style lang="scss" scoped>
.top-params__wrapper {
    width: 99%;
    display: flex;
    justify-content: space-between;
}
.fav {
    margin-left: 20px;
    fill: #9c9c9c;
    &__full {
        fill: rgb(255, 208, 0);
    }
}
.offers {
    margin-top: auto;
    max-width: 100%;
    &__count {
        font: 14px proxima-bold;
        letter-spacing: 0.01em;
    }
}
.info {
    min-height: 420px;
    display: flex;
    flex-direction: column;
    &__name {
        margin-bottom: 25px;
    }
}
.mobile-info {
    margin-bottom: 50px;
    &__name {
        margin-bottom: 25px;
        margin-top: 25px;
    }
}
.text {
    font: 14px proxima-regular;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: rgb(15, 42, 72);
}
.gray {
    color: rgba(180, 180, 180, 1);
}
.mobile-params__cont {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.params {
    margin-top: 15px;
    width: 100%;
    &__cont {
        width: 100%;
        margin-top: 15px;
        display:flex;
        justify-content: space-between;
    }
    &__title {
        font: 16px proxima-bold;
        letter-spacing: 0.01em;
    }
    &__container {
        margin-top: 10px;
    }
    &__row {
        display: flex;
        margin-bottom: 3px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__half_40 {
        width: 90%;
        display: flex;
    }
    &__half_60 {
        width: 60%;
        display: flex;
    }
    &__line {
        margin: 16px 3px 0 3px;
        flex: 1 1 auto;
        background: rgba(240, 240, 240, 1);
        height: 1px;
    }
}
</style>